<template>
  <div class="home-index p20" v-if="staff">
    <div class="info-user bg p20 mb20">
      <div class="left-user">
        <!-- {{staff}} -->
        <div class="img-user">
          <img
            :src="
              staff.image
                ? baseUrl + staff.image.path
                : '/img/avatar.jpeg'
            "
          />
        </div>
        <div class="info-u-neme">
          <div class="name-i">
            {{ staff.name }} {{ staff.last_name }} {{ staff.middle_name }}
          </div>
          <div class="type-i">
            {{ staff.position ? staff.position.name : "" }}
          </div>
        </div>
      </div>
      <div class="right-user">
        <router-link :to="{ name: 'profile' }" style="display:inline-block">
          <el-button icon="el-icon-setting" type="primary" round>
            <!-- {{ $t("message.settings") }} -->
          </el-button>
        </router-link>
       
        <!-- <router-link style="margin-left: 10px" :to="{ name: 'login' }"> -->
          <el-button icon="el-icon-switch-button" type="danger" round  @click.prevent="logout">
          </el-button>
        <!-- </router-link> -->
      </div>
    </div>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <!-- <div @click="takeTime"> -->
          <router-link :to="{ name: 'my_permission', params: { id: staffId} }">
          <div class="card-block-itme bg color1">
            <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-person-walking-arrow-right" />
            </div>
            <span>{{ $t("message.permission_ask") }}</span></div>
          </router-link>
        <!-- </div> -->
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <router-link :to="{ name: 'my_advances' }">
          <div class="card-block-itme bg color2">
             <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-sack-dollar" />
            </div>
            <span>{{ $t("message.avance") }}</span>
            </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <router-link :to="{ name: 'my_sick_leave' }">
          <div class="card-block-itme bg color7">
             <div class="for-icon">
              <!-- <i class="fa-solid fa-hospital-user"></i> -->
              <font-awesome-icon icon="fa-solid fa-hospital-user" />
              <!-- <font-awesome-icon icon="fa-solid fa-sack-dollar" /> -->
            </div>
            <span>{{ $t("message.sick_leave") }}</span>
            </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <!-- <div @click="takeTime"> -->
          <router-link :to="{ name: 'onlineWork', params: { id: staffId} }">
          <div class="card-block-itme bg color5">
            <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-house-laptop" />
              <!-- <font-awesome-icon icon="fa-solid fa-person-walking-arrow-right" /> -->
            </div>
            <span>{{ $t("message.online_work") }}</span></div>
          </router-link>
        <!-- </div> -->
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <!-- <div @click="vacation"> -->
          <router-link :to="{ name: 'my_vacations', params: { id: staffId} }" >
          <div class="card-block-itme bg color1">
             <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-umbrella-beach" />
            </div>
            <span>{{ $t("message.vacation") }}</span>
            </div>
          </router-link>
        <!-- </div> -->
      </el-col>   
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <router-link :to="{ name: 'complaintTable' }">
          <div class="card-block-itme bg color5">
             <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-user-shield" />
            </div>
            <span>
             {{ $t("message.complaint") }}
            </span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <!-- <div @click="table"> -->
        <router-link :to="{ name: 'table' }">
          <div class="card-block-itme bg color3">
            <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-calendar-days" />
            </div>
            <span>{{ $t("message.report_card") }}</span>
            </div>
        </router-link>
        <!-- </div> -->
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <router-link :to="{ name: 'additionalWork', params: { id: staffId}}">
          <div class="card-block-itme bg color6" style=" color:#bbb">
          <div class="for-icon">
            <font-awesome-icon icon="fa-solid fa-briefcase" />
          </div>
            <span>{{ $t("message.additional_work") }}</span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <router-link :to="{ name: 'my_evaluation' }">
         
          <div class="card-block-itme bg color2">
            <div class="for-icon">
            <!-- <font-awesome-icon icon="fa-solid fa-file-pen" /> -->
            <font-awesome-icon icon="fa-solid fa-star" />
           
          </div>
          <div>
             {{ $t("message.evaluation") }}
          </div></div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <router-link :to="{ name: 'bonuses' }">
          <div class="card-block-itme bg color3">
            <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-file-lines" /></div>
            <span>{{ $t("message.my_bonuses") }}</span>
            </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <router-link :to="{ name: 'penalties' }">
          <div class="card-block-itme bg color6">
            <div class="for-icon">
              <font-awesome-icon icon="fa-solid fa-file-lines" /></div>
            <span>{{ $t("message.my_penalties") }}</span>
            </div>
        </router-link>
      </el-col>
      <!-- <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <router-link :to="{ name: 'complaintTable' }">
         
          <div class="card-block-itme bg color6">
            <div class="for-icon">
            <font-awesome-icon icon="fa-solid fa-file-pen" />
          </div>
          <div>
             {{ $t("message.my_complaints") }}
          </div></div>
        </router-link>
      </el-col> -->
        <!-- <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <router-link :to="{ name: 'my_evaluation' }">
          <div class="card-block-itme bg color3">
            <div class="for-icon">
             <font-awesome-icon icon="fa-solid fa-ranking-star" />
          </div>
          <div>
             {{ $t("message.my_evaluation") }}
          </div></div>
        </router-link>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import { getItem } from "@/utils/storage";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 4
      ),
      staffId: null,
      // list: [],
    };
  },

  mounted() {
    if (getItem("userId") == null) {
      let route = this.$router.push({
        name: "login",
       
      });
      window.open(route.href, "_self");
    }

    this.getUser();
    this.staffId = getItem("userId");
  },
   computed:{
    ...mapGetters({
      staff:"assent/staff",
    })
  },
  methods: {
    ...mapActions({
      attendance: "attendanceReport/index",
      updateList: "assent/staffInfo",
    }),
    getUser() {      
      this.updateList(getItem('userId')).then((res) => {
        // console.log(res);
         if (res.status==200) { 
          //  this.staff = res.data.result.data.staff;
        }
        else{
           let route = this.$router.push({
              name: "login",
            });
        }
      });
    },
    takeTime() {
      let route = this.$router.push({
        name: "takeTime",
        params: {
          id: this.staffId,
        },
      });
      // this.staffId = null;
      window.open(route.href, "_self");
    },

    table() {
      this.attendance(this.staffId).then((res) => {
        let route = this.$router.push({
          name: "table",
          params: {
            id: this.staffId,
          },
        });
        // this.staffId = null;
        window.open(route.href, "_self");
      });
    },

     logout() {
            this.$confirm(
                "Вы уверены, что выйдете из системы?",
                "Авторизоваться",
                {
                    confirmButtonText: "Да",
                    cancelButtonText: "Отмен",
                    type: "warning",
                }
            )
                .then((n) => {
                    let route = this.$router.push({
                        name: "login",
                      });
                    window.open(route.href, "_self");
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "Операция отменена",
                    });
                });
        },
    pushProfile(){
      let route = this.$router.resolve({
        name: "profile"
      });

      window.open(route.href, false);
    }
  },
};
</script>
<style lang="scss">
.card-block-itme{
  display: flex;
  flex-direction: column;
  font-size: 24px;
  .for-icon{
    font-size: 80px;
  }
}
.scrol-table{
  overflow: hidden;
  overflow-y: scroll;
}
.scrol-table .off,
.scrol-table .yes{
  text-align: center;
  font-weight: 900 !important;
}
.scrol-table {
  overflow-y: auto !important;
}
.crmhome__scroll {
  overflow-x: auto !important;
  padding: 0 10px !important;
}
@media only screen and (max-width: 600px) {
  .left-user, .info-user {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .name-i{
    font-size: 20px;
    margin-top: 15px;
  }
  .el-message-box{
    width: auto !important;
  }
  .el-message{
    width: auto !important;
  }

  .card-table{
    .info_item{
      flex-direction: column;
      padding: 10px;
      .item_title, .item_value{
        width: 100%;
      }
    }
  }
  .p20{
    padding: 10px;
  }
  .mb-5{
    padding: 10px;

  }
  .img-user img{
    margin: 0;
  }
  .my-btn.el-button{
    padding: 8px 15px !important;
    font-size: 12px;
  }
  .header-filter{
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .title-btn-block{
    display: inline-flex;
    gap: 15px;
    flex-wrap: wrap;
    align-items: flex-start;

  }
}


</style>
