import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/online-works-add',
    name: 'onlineWorkAdd',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/onlineWork/index')
    
  },
  {
    path: '/online-works',
    name: 'onlineWork',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/onlineWork/my_online_works')
    
  }

]
